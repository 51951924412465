// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
  padding: 30px;
}

.App-logo {
	height: 5vmin;
	pointer-events: none;
}



.App-link {
	color: #61dafb;
}

.banner {
  width: 100%;
  background-color: #031530;
  border-radius: 5px;
  margin-top: 20px;
  
}


.title {
  font-size: 1.7em;
  font-weight: 700;
  color: white;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-left: 10px;
  @media (max-width: 600px) {
    /* Your code here */
    font-size: 1rem;
  }
  
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;CACC,aAAa;CACb,oBAAoB;AACrB;;;;AAIA;CACC,cAAc;AACf;;AAEA;EACE,WAAW;EACX,yBAAyB;EACzB,kBAAkB;EAClB,gBAAgB;;AAElB;;;AAGA;EACE,gBAAgB;EAChB,gBAAgB;EAChB,YAAY;EACZ,iBAAiB;EACjB,oBAAoB;EACpB,iBAAiB;EACjB;IACE,mBAAmB;IACnB,eAAe;EACjB;;AAEF","sourcesContent":[".App {\n  padding: 30px;\n}\n\n.App-logo {\n\theight: 5vmin;\n\tpointer-events: none;\n}\n\n\n\n.App-link {\n\tcolor: #61dafb;\n}\n\n.banner {\n  width: 100%;\n  background-color: #031530;\n  border-radius: 5px;\n  margin-top: 20px;\n  \n}\n\n\n.title {\n  font-size: 1.7em;\n  font-weight: 700;\n  color: white;\n  padding-top: 20px;\n  padding-bottom: 20px;\n  margin-left: 10px;\n  @media (max-width: 600px) {\n    /* Your code here */\n    font-size: 1rem;\n  }\n  \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
