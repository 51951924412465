import { PinwheelForm } from "./form"
import logo from "./assets/logo.svg"
import "./App.css"

export default function App() {
	return (
		<div className="App">
			<img src={logo} className="App-logo" alt="logo" />
			<div className="banner">
				<p className="title">Welcome to Our Testing Platform!</p>
			</div>
      <PinwheelForm />
		</div>
	)
}


