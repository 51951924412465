import React from "react"

import "./form.css"
import PinwheelModal from "@pinwheel/react-modal"

import { authenticated, pinwheel } from "../api/endpoints"

export default function PinwheelForm() {
	// state variables
	const [email, setEmail] = React.useState("")
	const [token, setToken] = React.useState("")
	const [disabled, setDisabled] = React.useState(false)
	const [isPinwheelModalOpen, setIsPinwheelModalOpen] = React.useState(false)

	// checks if user is authenticated and opens Pinwheel SDK
	const handleContinue = async () => {
		try {
			setDisabled(true)

			// check if email is valid
			if (!email || email === "" || !email.includes("@")) {
				alert("Please enter a valid email address")
				return
			}

			// GET authenticated user
			const res = await authenticated(email)

			if (res?.pinwheel) {
				setToken(res.pinwheel?.token)
				setIsPinwheelModalOpen(true)
			} else {
				
			}
		} catch (err) {
			console.log(err)
		} finally {
			setDisabled(false)
		}
	}
	
	
	// function that send pinwheel data to the service
	const handlePinwheel = async (data) => {
		try {
			// POST pinwheel data
			const res = await pinwheel({
				email,
				accountId: data.accountId,
				platformId: data.platformId,
			})
			
			if(res) {
				setEmail("")
				setToken("")
				alert("Test completed successfully!")
			}
			
		} catch (err) {
			console.log(err)
		}
	}

	return (
		<>
			<div>
				<div className="label">
					<label className="label-text">Please enter your email address</label>
					<p className="required">*</p>
				</div>
				<input
					className="input"
					placeholder="Email address..."
					value={email}
					onChange={(e) => setEmail(e.target.value)}
				/>
			</div>
			<button
				disabled={disabled}
				onClick={() => handleContinue()}
				className="button"
			>
				Continue
			</button>
			<PinwheelModal
				linkToken={token}
				open={isPinwheelModalOpen}
				onEvent={(event, data) => {
					if(event === "exit") {
						setIsPinwheelModalOpen(false)
					}
					if(event === "success") {
						handlePinwheel(data)
					}
				}}
			/>
		</>
	)
}
