// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.label {
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  
  .label-text {
    font-size: 1rem;
    font-weight: 400;
    color: gray;
   
  }
  
  .required {
    color: red;
  }
  
  .dot {
    background-color: #031530;
    font-size: 1.5rem;
    width: 7px;
    height: 7px;
    border-radius: 100%;
  }
}


.input {
  width: 50%;
  height: 30px;
  @media (max-width: 600px) {
    /* Your code here */
    width: 100%;
  }
}

.button {
  background-color: #031530;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 40px;
  font-size: 1rem;
  font-weight: 700;
  cursor: pointer;
  margin-top: 50px;
  @media (max-width: 600px) {
    /* Your code here */
    width: 100%;
  }
}`, "",{"version":3,"sources":["webpack://./src/form/form.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,QAAQ;EACR,mBAAmB;;EAEnB;IACE,eAAe;IACf,gBAAgB;IAChB,WAAW;;EAEb;;EAEA;IACE,UAAU;EACZ;;EAEA;IACE,yBAAyB;IACzB,iBAAiB;IACjB,UAAU;IACV,WAAW;IACX,mBAAmB;EACrB;AACF;;;AAGA;EACE,UAAU;EACV,YAAY;EACZ;IACE,mBAAmB;IACnB,WAAW;EACb;AACF;;AAEA;EACE,yBAAyB;EACzB,YAAY;EACZ,YAAY;EACZ,kBAAkB;EAClB,kBAAkB;EAClB,eAAe;EACf,gBAAgB;EAChB,eAAe;EACf,gBAAgB;EAChB;IACE,mBAAmB;IACnB,WAAW;EACb;AACF","sourcesContent":[".label {\n  display: flex;\n  flex-direction: row;\n  gap: 5px;\n  align-items: center;\n  \n  .label-text {\n    font-size: 1rem;\n    font-weight: 400;\n    color: gray;\n   \n  }\n  \n  .required {\n    color: red;\n  }\n  \n  .dot {\n    background-color: #031530;\n    font-size: 1.5rem;\n    width: 7px;\n    height: 7px;\n    border-radius: 100%;\n  }\n}\n\n\n.input {\n  width: 50%;\n  height: 30px;\n  @media (max-width: 600px) {\n    /* Your code here */\n    width: 100%;\n  }\n}\n\n.button {\n  background-color: #031530;\n  color: white;\n  border: none;\n  border-radius: 5px;\n  padding: 10px 40px;\n  font-size: 1rem;\n  font-weight: 700;\n  cursor: pointer;\n  margin-top: 50px;\n  @media (max-width: 600px) {\n    /* Your code here */\n    width: 100%;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
