import axios from "axios"

const HOST = "https://calctest.joinclockout.com";

// GETS authenticated user
export const authenticated = async (email) => {
	try {
		console.log(email)
		const response = await axios.get(
			`${HOST}/api/session/${email}/authenticated`
		)
		const result = await response.data
		return result
	} catch (error) {
		console.error(error)
	}
}


// POSTS pinwheel data
export const pinwheel = async (data) => {
	const { accountId, platformId, email } = data
	try {
		const response = await axios.post(
			`${HOST}/api/account/${email}/authenticate`,
			{
				auth: {
					accountId,
					platformId,
				},
			}
		)
		const result = await response.data
		console.log(result)
    return result
	} catch (error) {
		console.error(error)
	}
}
